import React, { ReactElement } from 'react';
import { LanguageControlDesktop } from '@hotelplan/components.common.language-control';
import { useLogoRoute } from '@hotelplan/fdr.hook.use-logo-route';
import { trackLanguageSwitch } from '@hotelplan/fdr.lib.track.header';
import trackMenu, { MenuEventType } from '@hotelplan/fdr.lib.track.menu';
import {
  useLanguage,
  useGetLanguageBasedURLs,
} from '@hotelplan/fdr.regular.basis.fdr-lang-control';
import { FdrB2bAuthPanelDesktop } from '@hotelplan/fdr.regular.fusion.fdr-b2b-auth-panel';
import {
  FdrDesktopNav,
  FdrDesktopNavMenuSkeleton,
} from '@hotelplan/fdr.regular.fusion.fdr-desktop-nav';
import { FdrHeaderLayout } from '@hotelplan/fdr.regular.fusion.fdr-header-layout';
import { usePageTransitionJustStarted } from '@hotelplan/libs.hooks';
import {
  FdrContactsSiteTool,
  FdrHistorySiteTool,
  FdrAccountSiteTool,
  FdrShareSiteTool,
  FdrWishlistSiteTool,
  SiteToolsWrapper,
} from 'fdr/components/local/fdr-site-tool-buttons';
import { useHeaderData } from './useHeaderData';

const FdrHeaderDesktop = (): ReactElement => {
  const { menus, logo, loading } = useHeaderData();
  const logoRoute = useLogoRoute();
  const { loading: languageLoading, urls } = useGetLanguageBasedURLs();
  const [, changeLanguage] = useLanguage();
  const transitionStarted = usePageTransitionJustStarted();

  const handleLanguageChange = (nextLanguage: string) => {
    trackLanguageSwitch(nextLanguage);
    void changeLanguage(nextLanguage);
  };

  return (
    <FdrHeaderLayout logo={logo} logoRouterProps={logoRoute}>
      {loading ? (
        <FdrDesktopNavMenuSkeleton />
      ) : (
        <FdrDesktopNav
          menus={menus}
          isSubMenuBlocked={transitionStarted}
          onLinkClick={(link, index, positionIndex) => {
            trackMenu({
              type: MenuEventType.MENU_ACTION,
              payload: {
                link: link?.url?.toString() || '',
                position: positionIndex ? [positionIndex, index] : index,
              },
            });
          }}
          internalLinkIconName={`chevron-right`}
        />
      )}
      <SiteToolsWrapper>
        <FdrWishlistSiteTool />
        <FdrContactsSiteTool />
        <FdrHistorySiteTool />
        <FdrShareSiteTool />
      </SiteToolsWrapper>
      <FdrAccountSiteTool />
      <LanguageControlDesktop
        loading={languageLoading}
        onChange={handleLanguageChange}
        urls={urls}
        className="language-control"
      />
      <FdrB2bAuthPanelDesktop />
    </FdrHeaderLayout>
  );
};

export default React.memo(FdrHeaderDesktop);
